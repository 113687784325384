/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react'

import isBrowser from '../../utils'
import { ABI, ADDRESS } from '../../../config'

const Web3 = isBrowser() ? require('web3') : null

const Mint = () => {
  // FOR WALLET
  const [signedIn, setSignedIn] = useState(false)
  const [walletAddress, setWalletAddress] = useState(null)
  const [incompatibleBrowser, setIncompatibleBrowser] = useState(false)

  // FOR MINTING
  const [howManyHuman, setHowManyHuman] = useState(1)
  const [nftContract, setNFTContract] = useState(null)

  // INFO FROM SMART Contract
  const [totalSupply, setTotalSupply] = useState(0)
  const [saleStarted, setSaleStarted] = useState(false)
  const [nftPrice, setNFTPrice] = useState(0)
  const [maxHuman, setMaxHuman] = useState(0)
  const [maxHumanPurchase, setMaxHumanPurchase] = useState(0)

  async function callContractData(_wallet) {
    // let balance = await web3.eth.getBalance(wallet);
    // setWalletBalance(balance)
    if (!isBrowser()) {
      return
    }
    const nftContractTemp = new window.web3.eth.Contract(ABI, ADDRESS)
    setNFTContract(nftContractTemp)

    const salebool = await nftContractTemp.methods.isSaleActive().call()
    setSaleStarted(salebool)

    const totalSupplyCall = await nftContractTemp.methods.totalSupply().call()
    setTotalSupply(totalSupplyCall)

    const maxHumanCall = await nftContractTemp.methods.MAXHUMANS().call()
    setMaxHuman(maxHumanCall)

    const maxHumanPurchaseCall = await nftContractTemp.methods
      .maxHumansPurchase()
      .call()
    setMaxHumanPurchase(parseInt(maxHumanPurchaseCall, 10))

    const nftPriceCall = await nftContractTemp.methods._price().call()
    // const nftPrice = 0.06 * 10**18
    setNFTPrice(nftPriceCall)
  }

  async function signIn() {
    if (!isBrowser()) {
      return
    }

    if (typeof window.web3 !== 'undefined') {
      // Use existing gateway
      window.web3 = new Web3(window.ethereum)
    } else {
      setIncompatibleBrowser(true)
      alert('No Ethereum interface injected into browser. Read-only access')
    }

    window.ethereum
      .enable()
      .then((accounts) => {
        window.web3.eth.net
          .getNetworkType()
          // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
          .then((network) => {
            // use rinkeby for test
            // if (network !== 'rinkeby') {
            if (network !== 'main') {
              alert(
                `You are on ${network} network. Change network to mainnet or you won't be able to do anything here`,
              )
            }
          })
        const wallet = accounts[0]
        setWalletAddress(wallet)
        setSignedIn(true)
        callContractData(wallet)
      })
      .catch((error) => {
        // Handle error. Likely the user rejected the login
        console.error(error)
      })
  }

  useEffect(async () => {
    signIn()
  }, [])

  async function signOut() {
    setSignedIn(false)
  }

  async function mintNFT(howManyHuman2) {
    if (nftContract) {
      const price = Number(nftPrice) * howManyHuman2

      const gasAmount = await nftContract.methods
        .mintNFT(howManyHuman2)
        .estimateGas({ from: walletAddress, value: price })
      console.log('estimated gas', gasAmount)

      console.log({ from: walletAddress, value: price })

      const mintPromise = await nftContract.methods
        .mintNFT(howManyHuman2)
        .send({ from: walletAddress, value: price, gas: String(gasAmount) })
        .on('transactionHash', (hash) => {
          console.log('transactionHash', hash)
        })
        .on('error', (error) => {
          console.log(`error 1: ${error}`)
          return false
        })

      console.log(mintPromise)
    } else {
      console.log('Wallet not connected')
    }
  }

  return (
    <div id="mint" className="">
      <div className="">
        {!signedIn ? (
          <button type="button" onClick={signIn} className="button">
            Connect Wallet with Metamask
          </button>
        ) : (
          <button
            type="button"
            onClick={signOut}
            className="button mb-2"
            style={{ maxWidth: '100%' }}
          >
            <span
              style={{
                maxWidth: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'block',
                textOverflow: 'ellipsis',
              }}
            >
              Wallet Connected: {walletAddress}
            </span>
          </button>
        )}
      </div>

      <div className="">
        {/* <span className="">
          Total Humans minted:{' '}
          <span className="">
            {' '}
            {!signedIn ? <>-</> : totalSupply} / {maxHuman}{' '}
          </span>
        </span> */}
        <div className="">
          <span className="mr-2">Mint</span>

          <input
            type="number"
            min="1"
            max={String(maxHumanPurchase)}
            value={howManyHuman}
            onChange={(e) => {
              if (e.target.value <= maxHumanPurchase) {
                setHowManyHuman(parseInt(e.target.value, 10))
              }
            }}
            name=""
            className="input"
          />
          <span className="ml-2">{howManyHuman > 1 ? 'Humans' : 'Human'}!</span>
        </div>
        {saleStarted ? (
          <button
            type="button"
            onClick={() => mintNFT(howManyHuman)}
            className="button"
          >
            MINT {howManyHuman} {howManyHuman > 1 ? 'Humans' : 'Human'} for{' '}
            {(nftPrice * howManyHuman) / 10 ** 18} ETH + GAS
          </button>
        ) : (
          <button type="button" className="button">
            {walletAddress
              ? 'SALE IS NOT YET ACTIVE'
              : 'SALE IS NOT ACTIVE OR NO WALLET IS CONNECTED'}
          </button>
        )}
      </div>
      {/* {incompatibleBrowser && ( */}
      <p style={{ color: 'red', fontSize: 14 }}>
        {/* Web3 is not running in your browser.  */}
        Make sure to use a Browser with the{' '}
        <a
          href="https://metamask.io/download.html"
          rel="nofollow noopener noreferrer"
          target="_blank"
          className="link"
        >
          MetaMask
        </a>{' '}
        Plugin installed and active.
      </p>
      {/* )} */}
      <p style={{ color: 'red', fontSize: 14 }}>
        Before clicking the mint button, check that you have enough ETH in your
        wallet.
      </p>
    </div>
  )
}

export default Mint
