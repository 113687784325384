import React from 'react'
import BgBlock from '../components/bg-block'
import Layout from '../components/layout/layout'
import Mint from '../components/mint'
import '../styles/main.scss'

// markup
const IndexPage = () => (
  <Layout isHomePage metaTitle="22 percent - mint" metaDescription="Mint">
    <BgBlock
      color="var(--bg-block-color-4)"
      backgroundColor="var(--bg-block-bg-color-4)"
    >
      <h2>Mint</h2>
      <Mint />
    </BgBlock>
  </Layout>
)

export default IndexPage
